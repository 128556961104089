import {
	Select as CommonSelect,
	Action as CommonAction,
} from '@gisatcz/ptr-state';
import Select from '../../Select';
import {
	benchmarkArea1SelectionKey,
	benchmarkArea2SelectionKey,
	benchmarkViewKey,
	benchmarkMap1Key,
	benchmarkMap2Key,
	auSelectionKey,
	reportViewKey,
	datasetsComparisonType,
	areaComparisonType,
	cityClustersAreaTreeKey,
	admLayerKey,
	regionsAreaTreeKey,
} from '../../../constants/app';
import datasets from '../../../data/datasets';
import {
	getOriginalLayerKey,
	getDatasetLayerKeysToRemoveUpdate,
	getLayerForYear,
	getCogLayerKey,
} from './helpers';
import outlinesLayers from '../../../data/layers/outlinesLayers';

const setLayerOpacity = (mapKey, layerKey, layerTemplateKey, opacity) => {
	return CommonAction.maps.setMapLayerOpacity(mapKey, layerKey, opacity / 100);
};

const setLayerInteractivity = (mapKey, layerKey, interactive) => {
	return dispatch => {
		dispatch(
			CommonAction.maps.setMapLayerOption(
				mapKey,
				layerKey,
				'selectable',
				interactive,
			),
		);
		dispatch(
			CommonAction.maps.setMapLayerOption(
				mapKey,
				layerKey,
				'hoverable',
				interactive,
			),
		);
	};
};

const updateLayersWithActivePeriod = period => {
	return (dispatch, getState) => {
		const state = getState();
		const mapSetKey = CommonSelect.maps.getActiveSetKey(state);
		const maps = CommonSelect.maps.getMapSetMaps(state, mapSetKey);
		const activeViewKey = CommonSelect.views.getActiveKey(state);
		maps.forEach(map => {
			const mapKey = map.key;
			const layers = map?.data?.layers;
			if (layers?.length) {
				dispatch(CommonAction.maps.removeAllMapLayers(mapKey));
				const updatedLayers = [];

				const layerKeysToRemoveUpdate = getDatasetLayerKeysToRemoveUpdate(
					layers.filter(layer => layer.changeByPeriod).map(layer => layer.key),
					period && Number(period.split('/')[1]),
				);
				layers?.forEach(layer => {
					if (layer.dependingOnActivePeriod) {
						const newLayerKey = isLayerCog(getState(), layer)
							? getCogLayerKey(layer.key, period)
							: layer.key;
						const updatedLayer =
							Select.unhab.layers.updateLayerStyleWithActivePeriod(
								{...layer, key: newLayerKey},
								period,
							);

						updatedLayers.push(updatedLayer);
					} else if (
						layer.changeByPeriod &&
						(activeViewKey === benchmarkViewKey ||
							activeViewKey === reportViewKey)
					) {
						const layerKeyToRemain = layerKeysToRemoveUpdate.remain.find(
							layerKey => layerKey === layer.key,
						);

						const layerToUpdate = layerKeysToRemoveUpdate.update.find(
							definition => definition.fromKey === layer.key,
						);

						if (layerKeyToRemain) {
							updatedLayers.push(layer);
						} else if (layer.key === layerToUpdate?.fromKey) {
							// TODO while change, preserve layer opacity
							updatedLayers.push(layerToUpdate.newLayer);
							dispatch(
								CommonAction.layerTemplates.useKeys([
									layerToUpdate.newLayer.layerTemplateKey,
								]),
							);
						}
					} else {
						updatedLayers.push(layer);
					}
				});
				dispatch(CommonAction.maps.addMapLayers(mapKey, updatedLayers));
			}
		});
	};
};

const setLayerLegendHidden = (mapKey, layerKey, layerTemplateKey, hide) => {
	return (dispatch, getState) => {
		// TODO setup this function in ptr-state
		// dispatch(CommonAction.maps.setMapLayerParameter(mapKey, layerKey, layerTemplateKey, 'hideLegend', hide));

		const layers = CommonSelect.maps.getMapLayersStateByMapKey(
			getState(),
			mapKey,
		);

		if (layers) {
			// TODO @vdubr @vlach1989 will be replaced with CommonAction.maps.setMapLayerOption
			dispatch(CommonAction.maps.removeAllMapLayers(mapKey));
			const updatedLayers = layers?.map(layer => {
				if (
					(layer?.layerTemplateKey &&
						layerTemplateKey &&
						layer?.layerTemplateKey === layerTemplateKey) ||
					(layer?.key && layerKey && layer?.key === layerKey)
				) {
					return {
						...layer,
						hideLegend: hide,
					};
				} else {
					return layer;
				}
			});

			dispatch(CommonAction.maps.addMapLayers(mapKey, updatedLayers));
		}
	};
};

const sortLayers = (mapKey, fromIndex, toIndex, layers) => {
	return dispatch => {
		const currentLayers = layers.reverse();
		const mapLayers = [];

		if (currentLayers?.length) {
			const sortedLayers = [...currentLayers].reverse();
			const item = sortedLayers.splice(fromIndex, 1)[0];
			sortedLayers.splice(toIndex, 0, item);

			sortedLayers.forEach(layer => {
				if (layer?.layers) {
					layer.layers.forEach(nestedLayer => mapLayers.push(nestedLayer));
				} else {
					mapLayers.push(layer);
				}
			});

			dispatch(CommonAction.maps.removeAllMapLayers(mapKey));
			dispatch(CommonAction.maps.addMapLayers(mapKey, mapLayers.reverse()));
		}
	};
};

const setAllMapsBackgroundLayerByMode = () => {
	return (dispatch, getState) => {
		const mapsInUse = CommonSelect.maps.getAllMapsInUse(getState());
		if (mapsInUse?.length) {
			const layer =
				Select.unhab.layers.getDefaultBackgroundLayerByAppMode(getState());

			if (layer) {
				mapsInUse.forEach(mapKey => {
					dispatch(CommonAction.maps.setMapBackgroundLayer(mapKey, layer));
				});
			}
		}
	};
};

const setLayerSelectedFeatureKeys = (mapKey, layerKey, selectedFeatureKeys) => {
	return (dispatch, getState) => {
		const state = getState();
		const viewKey = Select.views.getActiveKey(state);

		// forbiden interaction when cityClusters are active
		const cityClustersActive =
			Select.unhab.areaTreeTypes.getActiveKey(state) ===
			cityClustersAreaTreeKey;

		// specific selection for benchmark layers
		if (
			(!cityClustersActive && viewKey === benchmarkViewKey) ||
			viewKey === reportViewKey
		) {
			const comparisonType =
				Select.unhab.benchmarkComparisonTypes.getActiveKey(state);
			const featureKey = selectedFeatureKeys?.[0];

			if (comparisonType === datasetsComparisonType) {
				dispatch(
					CommonAction.selections.setFeatureKeysFilterKeys(
						benchmarkArea1SelectionKey,
						[featureKey],
					),
				);
				dispatch(
					CommonAction.selections.setFeatureKeysFilterKeys(
						benchmarkArea2SelectionKey,
						[featureKey],
					),
				);
			} else {
				if (mapKey === benchmarkMap1Key) {
					dispatch(
						CommonAction.selections.setFeatureKeysFilterKeys(
							benchmarkArea1SelectionKey,
							[featureKey],
						),
					);
				} else if (mapKey === benchmarkMap2Key) {
					dispatch(
						CommonAction.selections.setFeatureKeysFilterKeys(
							benchmarkArea2SelectionKey,
							[featureKey],
						),
					);
				}
			}
		}

		// standard selection
		else {
			dispatch(
				CommonAction.maps.setLayerSelectedFeatureKeys(
					mapKey,
					layerKey,
					selectedFeatureKeys,
				),
			);
		}
	};
};

const onLayerChange = (type, data, mapKey, datasetLayerKey, isDatasetGroup) => {
	return (dispatch, getState) => {
		const state = getState();

		// hack for passing specific selection to general layer definition
		let finalData = data;
		if (data.options.selectable && !data.options.selected) {
			let selected = {[auSelectionKey]: {}};
			const activeViewKey = Select.views.getActiveKey(state);
			if (
				activeViewKey === benchmarkViewKey ||
				activeViewKey === reportViewKey
			) {
				if (mapKey === benchmarkMap1Key) {
					selected = {[benchmarkArea1SelectionKey]: {}};
				} else {
					selected = {[benchmarkArea2SelectionKey]: {}};
				}
			}

			finalData = {
				...data,
				options: {
					...data.options,
					selected,
				},
			};
		}

		if (datasetLayerKey) {
			const datasetLayers = Select.unhab.layers.getLayersByDatasetLayerKey(
				state,
				mapKey,
				datasetLayerKey,
			);
			let opacity = datasetLayers?.[0]?.opacity;
			let alpha = datasetLayers?.[0]?.options?.alpha;
			let legendHidden = datasetLayers?.[0]?.hideLegend;

			if (isDatasetGroup) {
				const period = Select.periods
					.getActive(state)
					?.data?.period.split('/')[1];
				finalData = getLayerForYear(finalData.key, Number(period));
			}

			if (opacity === 0 || opacity > 0) {
				finalData = {
					...finalData,
					opacity: opacity,
					options: {...finalData?.options, alpha: alpha},
				};
			}

			if (legendHidden) {
				finalData = {
					...finalData,
					hideLegend: legendHidden,
				};
			}
		}

		const activeLayers =
			Select.maps.getMapLayersStateByMapKey(state, mapKey) || [];

		if (type === 'radio') {
			// if radio, then remove layer from same group first
			let layerFromSameGroupIndex =
				activeLayers?.length && activeLayers?.length - 1;
			if (activeLayers?.length) {
				const group = data?.radioGroup;
				if (group) {
					const layerFromSameGroup = activeLayers?.find(
						layer => layer.radioGroup === data?.radioGroup,
					);
					layerFromSameGroupIndex = activeLayers?.findIndex(
						layer => layer.radioGroup === data?.radioGroup,
					);
					if (layerFromSameGroup) {
						dispatch(
							CommonAction.maps.removeMapLayer(mapKey, layerFromSameGroup.key),
						);
					}
				}
			}
			if (layerFromSameGroupIndex > -1) {
				dispatch(
					CommonAction.maps.addMapLayerToIndex(
						mapKey,
						finalData,
						layerFromSameGroupIndex,
					),
				);
			} else {
				dispatch(CommonAction.maps.addMapLayers(mapKey, [finalData]));
			}
		} else {
			if (data?.layerTemplateKey) {
				dispatch(
					CommonAction.maps.removeMapLayersByLayerTemplateKey(
						mapKey,
						data.layerTemplateKey,
					),
				);
			} else {
				dispatch(CommonAction.maps.removeMapLayer(mapKey, data.key));
			}
			dispatch(CommonAction.maps.addMapLayers(mapKey, [finalData]));
		}
	};
};
const removeLayer = (layerTemplateKey, layerKey, mapKey) => {
	return dispatch => {
		if (layerTemplateKey) {
			dispatch(
				CommonAction.maps.removeMapLayersByLayerTemplateKey(
					mapKey,
					layerTemplateKey,
				),
			);
		} else {
			dispatch(CommonAction.maps.removeMapLayer(mapKey, layerKey));
		}
	};
};

const removeLayersByDatasetLayerKey = (datasetLayerKey, mapKey) => {
	return (dispatch, getState) => {
		if (datasetLayerKey) {
			const state = getState();
			const activeDatasetLayers =
				Select.unhab.layers.getLayersByDatasetLayerKey(
					state,
					mapKey,
					datasetLayerKey,
				);

			activeDatasetLayers.forEach(layer => {
				dispatch(
					CommonAction.maps.removeMapLayersByLayerTemplateKey(
						mapKey,
						layer?.layerTemplateKey,
					),
				);
			});
		}
	};
};

const setLayersOpacityByDatasetLayerKey = (
	mapKey,
	datasetLayerKey,
	opacity,
) => {
	return (dispatch, getState) => {
		const layers = CommonSelect.maps.getMapLayersStateByMapKey(
			getState(),
			mapKey,
		);

		if (layers) {
			let dataset = datasets?.find(item => {
				return item?.key === datasetLayerKey;
			});
			layers?.forEach(layer => {
				if (
					dataset?.data?.layerKeys?.includes(
						getOriginalLayerKey(layer?.key, layer?.options?.alpha),
					)
				) {
					dispatch(
						CommonAction.maps.setMapLayerOpacity(
							mapKey,
							layer?.key,
							opacity / 100,
						),
					);
				} else {
					return layer;
				}
			});
		}
	};
};

const setLayerLegendHiddenByDatasetLayerKey = (
	mapKey,
	datasetLayerKey,
	hide,
) => {
	return (dispatch, getState) => {
		// TODO setup this function in ptr-state
		// dispatch(CommonAction.maps.setMapLayerParameter(mapKey, layerKey, layerTemplateKey, 'hideLegend', hide));

		const layers = CommonSelect.maps.getMapLayersStateByMapKey(
			getState(),
			mapKey,
		);

		let dataset = datasets?.find(item => {
			return item?.key === datasetLayerKey;
		});

		// TODO @vdubr @vlach1989 will be replaced with CommonAction.maps.setMapLayerOption
		if (layers && datasetLayerKey) {
			dispatch(CommonAction.maps.removeAllMapLayers(mapKey));
			const updatedLayers = layers?.map(layer => {
				if (
					dataset?.data?.layerKeys?.includes(
						getOriginalLayerKey(layer?.key, layer?.options?.alpha),
					)
					// (layer?.layerTemplateKey &&
					// 	layerTemplateKey &&
					// 	layer?.layerTemplateKey === layerTemplateKey) ||
					// (layer?.key && layerKey && layer?.key === layerKey)
				) {
					return {
						...layer,
						hideLegend: hide,
					};
				} else {
					return layer;
				}
			});

			dispatch(CommonAction.maps.addMapLayers(mapKey, updatedLayers));
		}
	};
};

const handleLayersOnDatasetChange = () => {
	return (dispatch, getState) => {
		const state = getState();
		const viewKey = Select.views.getActiveKey(state);

		// handle just for benchmark or report
		if (viewKey === benchmarkViewKey || viewKey === reportViewKey) {
			const mapSetKey = Select.maps.getActiveSetKey(state);
			const maps = Select.maps.getMapSetMaps(state, mapSetKey);
			const areaTreeType = Select.unhab.areaTreeTypes.getActiveKey(state);
			const comparisonType =
				Select.unhab.benchmarkComparisonTypes.getActiveKey(state);
			const fullPeriod = Select.periods.getActive(state)?.data?.period;
			const currentYear = Select.periods
				.getActive(state)
				?.data?.period?.split('/')?.[1];

			// Get default layer keys
			let datasetsDefaultIndicatorLayerKeys, datasetsDefaultDatasetLayerKeys;
			if (areaTreeType === regionsAreaTreeKey) {
				const datasets = Select.unhab.datasets.getActiveModels(state);
				datasetsDefaultIndicatorLayerKeys = datasets?.map(
					dataset => dataset.data.defaultIndicatorLayerKey,
				);
				datasetsDefaultDatasetLayerKeys = datasets?.map(
					dataset => dataset.data.defaultDatasetLayerKey,
				);
			} else if (areaTreeType === cityClustersAreaTreeKey) {
				const methods = Select.unhab.cityClusterMethods.getActiveModels(state);
				datasetsDefaultDatasetLayerKeys = methods?.map(
					method => method.data.defaultDatasetLayerKey,
				);
			}

			maps.forEach((map, index) => {
				// Get default raster layer data
				let defaultDatasetLayer;
				if (comparisonType === areaComparisonType) {
					defaultDatasetLayer = getLayerForYear(
						datasetsDefaultDatasetLayerKeys[0],
						currentYear,
						fullPeriod,
					);
				} else {
					defaultDatasetLayer = getLayerForYear(
						datasetsDefaultDatasetLayerKeys[index],
						currentYear,
						fullPeriod,
					);
				}

				map.data.layers.forEach(layer => {
					const isIndicatorLayer = layer?.options?.attributes?.relative;
					const isReferenceLayer = !!outlinesLayers[layer.key];

					// Change indicator layer to respect selected datasets
					if (isIndicatorLayer && areaTreeType === regionsAreaTreeKey) {
						if (comparisonType === areaComparisonType) {
							if (layer.key === datasetsDefaultIndicatorLayerKeys[0]) {
								// layer already in map, do nothing
							} else {
								dispatch(CommonAction.maps.removeMapLayer(map.key, layer.key));
								const newLayer =
									Select.unhab.layers.getIndicatorLayerByLayerKey(
										datasetsDefaultIndicatorLayerKeys[0],
										map.key,
									);
								dispatch(
									CommonAction.maps.addMapLayerToIndex(map.key, newLayer, 0),
								);
							}
						} else {
							if (layer.key === datasetsDefaultIndicatorLayerKeys[index]) {
								// layer already in map, do nothing
							} else {
								dispatch(CommonAction.maps.removeMapLayer(map.key, layer.key));
								const newLayer =
									Select.unhab.layers.getIndicatorLayerByLayerKey(
										datasetsDefaultIndicatorLayerKeys[index],
										map.key,
									);
								dispatch(
									CommonAction.maps.addMapLayerToIndex(map.key, newLayer, 0),
								);
							}
						}
					} else if (isReferenceLayer) {
						// Keep map layer in map
					}

					// Remove raster layer if not default
					else if (layer?.key !== defaultDatasetLayer?.key) {
						dispatch(CommonAction.maps.removeMapLayer(map.key, layer.key));
					}
				});

				const isDatasetLayerPresent = map.data.layers.some(layer => {
					return layer?.key === defaultDatasetLayer?.key;
				});
				if (!isDatasetLayerPresent && defaultDatasetLayer) {
					dispatch(
						CommonAction.maps.addMapLayerToIndex(map.key, defaultDatasetLayer),
					);
					dispatch(
						CommonAction.layerTemplates.useKeys([
							defaultDatasetLayer?.layerTemplateKey,
						]),
					);
				}

				// Move administrative layer on the top
				const admLayer = map.data.layers.find(
					layer => layer.key === admLayerKey,
				);
				if (admLayer) {
					dispatch(CommonAction.maps.removeMapLayer(map.key, admLayer.key));
					dispatch(
						CommonAction.maps.addMapLayerToIndex(
							map.key,
							admLayer,
							map.data.layers.length,
						),
					);
				}
			});
		}
	};
};

// Helpers ---------------------------------------------------
// TODO temporary helpers, remove after fix in ptr-maps/ptr-state
function isLayerCog(state, layerState) {
	let isCog = false;
	const dataSources = CommonSelect.data.spatialDataSources.getIndexed(state, {
		layerTemplateKey: layerState?.layerTemplateKey,
	});
	if (dataSources) {
		isCog = dataSources.some(obj => obj.data?.type === 'cogBitmap');
	}

	return isCog;
}

export default {
	onLayerChange,
	removeLayer,
	setLayerOpacity,
	setLayerInteractivity,
	setLayerLegendHidden,
	setLayerSelectedFeatureKeys,
	setAllMapsBackgroundLayerByMode,
	sortLayers,
	removeLayersByDatasetLayerKey,
	setLayersOpacityByDatasetLayerKey,
	setLayerLegendHiddenByDatasetLayerKey,
	updateLayersWithActivePeriod,
	handleLayersOnDatasetChange,
};
