import highlightedBorderOutlines from '../styles/highlightedBorderOutlines';
import {admLayerKey, admLayerName} from '../../constants/app';

export default {
	[admLayerKey]: {
		nameDisplay: admLayerName,
		key: admLayerKey,
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		options: {
			selectable: true,
			customLegend: {
				attributeValues: {
					'Featured areas': {
						color: '#32516b',
						outlineWidth: 2,
					},
				},
			},
			style: highlightedBorderOutlines,
		},
	},
	buildingsHeigthWsf2019: {
		key: 'buildingsHeigthWsf2019',
		layerTemplateKey: 'eae3d186-62f9-4c09-b420-bdcd1d57516a',
		// cog explorer link
		// https://gisat-panther.github.io/app-gisat-cog-explorer/?cogUrl=https%3A%2F%2Fgisat-gis.eu-central-1.linodeobjects.com%2FesaUtepUnHabitat%2Frasters%2Fregional%2Fzoom3-4-3_Kenya2_COGeoN.tif&lon=36.8266715740551&lat=-1.2952092897910317&boxRange=2653.392825196379&useAutoRange=false&useChannel=1&clipLow=0&clipHigh=110&useColorsBasedOnValues=false&useSingleColor=false&useHeatMap=true&useDataForOpacity=false&colorScale=%22%2371abd4%22%2C%22%2393d7fe%22%2C%22%23fff156%22%2C%22%23f77f1e%22%2C%22%23e85530%22%2C%22%23be3e10%22%2C%22%23952b0b%22%2C%22%2348080b%22%5D&alpha=100&colorScaleValueRange=%5B1%2C+5%2C+10%2C+15%2C+20%2C+40%2C+60%2C+110%5D&blurredTexture=false&clippedColor=&unidentifiedColor=&nullColor=&colorsBasedOnValues=
		tags: [
			'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
			'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert

			'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
			'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global

			'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
			'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
			'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land

			'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
		],
		options: {
			unit: 'avg buildings height m',
			cogBitmapOptions: {
				clipHigh: 110,
				clipLow: -1,
				colorScale: [
					'#71abd4',
					'#93d7fe',
					'#fff156',
					'#f77f1e',
					'#e85530',
					'#be3e10',
					'#952b0b',
					'#48080b',
				],
				colorScaleValueRange: [0, 5, 10, 15, 20, 40, 60, 110],
				useChannel: 1,
				blurredTexture: false,
			},
		},
	},
	WSF2019BuiltUp_10m: {
		key: 'WSF2019BuiltUp_10m',
		layerTemplateKey: '991880cd-795a-41d5-a416-47071f7ccc4e',
		tags: [
			'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert

			'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
			'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global

			'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
			'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land

			'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			'1688cdcd-4613-459a-8cd9-638eb1cdb5d0', // SDG
		],
		options: {
			cogBitmapOptions: {
				useChannel: 1,
				blurredTexture: false,
				useColorsBasedOnValues: true,
				colorsBasedOnValues: [[255, '#ff0000']],
			},
			customLegend: {
				attributeValues: {
					'Settlement footprint': {
						color: '#ff0000',
						outlineWidth: 2,
					},
				},
			},
		},
	},
};
