import PropTypes from 'prop-types';
import {isArray as _isArray} from 'lodash';
import {admLayerName} from '../../../../../../constants/app';
import './style.scss';

const LayerLabel = ({activeLayers, cityClustersActive, feature, model}) => {
	const layerLabels = activeLayers?.reverse();
	const layerLabel =
		layerLabels?.[0] === admLayerName && layerLabels?.length > 1
			? layerLabels?.[1]
			: layerLabels?.[0];

	const layerName = _isArray(layerLabel) ? layerLabel?.[0] : layerLabel;
	const layerDetail = _isArray(layerLabel) ? layerLabel?.[1] : '';

	return (
		<div className="unhab-MapLabels-layers">
			<div className="unhab-MapLabels-layersLabel">
				{cityClustersActive
					? feature?.data?.name + ' '
					: (layerName || 'Layer unset') + ' '}
				<span className="unhab-MapLabels-layersLabelDetail">
					{cityClustersActive
						? model?.data?.nameDisplay + ' '
						: (layerDetail || '') + ' '}
				</span>
			</div>
		</div>
	);
};

LayerLabel.propTypes = {
	activeLayers: PropTypes.array,
	model: PropTypes.object,
	feature: PropTypes.object,
	cityClustersActive: PropTypes.bool,
};

export default LayerLabel;
