import {Action as CommonAction} from '@gisatcz/ptr-state';

import {
	// auSelectionKey,
	benchmarkViewKey,
	exploreMap2Key,
	defaultBenchmarkComparisonType,
	defaultCoverageTagKey,
	defaultExploreAreaTreeLevelKey,
	defaultPeriod,
	defaultSdgTagKey,
	defaultThemeTagKey,
	defaultViewKey,
	tagBaseKey,
	admLayerKey,
	reportViewKey,
	xCubeViewKey,
	limassolAreasSelectionKey,
	auSelectionKey,
	benchmarkArea1SelectionKey,
	benchmarkArea2SelectionKey,
	datasetsComparisonType,
	areaComparisonType,
} from '../../constants/app';
import configuration from './configuration/actions';
import cityClusterMethods from './cityClusterMethods/actions';
import cityClusterMethodParameters from './cityClusterMethodParameters/actions';
import datasets from './datasets/actions';
import datasetSets from './datasetSets/actions';
import indicatorsForList from './indicatorsForList/actions';
import providers from './providers/actions';
import layers from './layers/actions';
import features from './features/actions';
import featuresForScatterPlot from './featuresForScatterPlot/actions';
import admStructure from './admStructure/actions';
import udtStructure from './udtStructure/actions';
import parameterValues from './parameterValues/actions';
import cityClusterFeatures from './cityClusterFeatures/actions';
import benchmark from './benchmark/actions';
import areaTreeTypes from './areaTreeTypes/actions';
import benchmarkComparisonTypes from './benchmarkComparisonTypes/actions';
import tags from './tags/actions';
import Select from '../Select';
import Action from '../Action';
import {
	benchmarkArea1Selection,
	benchmarkArea2Selection,
} from '../../data/selections/benchmarkSelection';
import auSelection from '../../data/selections/auSelection';

const applyAndSetActive = (viewKey, Action) => {
	return (dispatch, getState) => {
		return dispatch(CommonAction.views.applyAndSetActive(viewKey, Action)).then(
			() => {
				const view = Select.views.getByKey(getState(), viewKey);
				if (view?.data?.state?.unhab?.configuration) {
					dispatch(
						configuration.updateStateFromView(
							view.data.state.unhab.configuration,
						),
					);
				}
				if (view?.data?.state?.unhab?.areaTreeTypes) {
					dispatch(
						areaTreeTypes.updateStateFromView(
							view.data.state.unhab.areaTreeTypes,
						),
					);
				}
			},
		);
	};
};

function setMapSetSync(mapSetKey, sync) {
	return dispatch => {
		let syncObject = {};
		if (sync) {
			syncObject = {
				roll: true,
				tilt: true,
				range: true,
				center: true,
				heading: true,
				boxRange: true,
			};
		}
		dispatch(CommonAction.maps.setMapSetSync(mapSetKey, syncObject));
	};
}
function setMapSetSyncJustScale(mapSetKey, sync) {
	return dispatch => {
		let syncObject = {};
		if (sync) {
			syncObject = {
				roll: true,
				tilt: true,
				range: true,
				center: false,
				heading: true,
				boxRange: true,
			};
		}
		dispatch(CommonAction.maps.setMapSetSync(mapSetKey, syncObject));
	};
}
function setMapsCount(mapSet, mapsCount) {
	return (dispatch, getState) => {
		const state = getState();
		const maps = Select.maps.getMapSetMaps(state, mapSet);
		let mapSetMapCount = (maps && maps.length) || 0;
		if (mapSetMapCount > mapsCount) {
			while (mapSetMapCount > mapsCount) {
				const lastMapKey = maps[mapSetMapCount - 1].key;
				dispatch(CommonAction.maps.removeMap(lastMapKey));
				mapSetMapCount = mapSetMapCount - 1;
			}

			const mode = Select.components.get(state, 'Maps', 'mode');
			if (mode === 'compare') {
				dispatch(Action.components.set('Maps', 'mode', null));
			}
		} else if (mapSetMapCount < mapsCount) {
			while (mapSetMapCount < mapsCount) {
				const mapKeyToBeAdd = exploreMap2Key;

				const firstMapSetMapKey = Select.maps.getMapSetByKey(state, mapSet)
					.maps[0];
				const indicatorLayer =
					Select.unhab.layers.getActiveIndicatorLayerByMapKey(
						state,
						firstMapSetMapKey,
					);
				dispatch(CommonAction.maps.addMap({key: mapKeyToBeAdd}));

				if (indicatorLayer) {
					delete indicatorLayer.metadataModifiers;

					if (indicatorLayer.filterByActive) {
						indicatorLayer.filterByActive.areaTreeLevel = true;
					} else {
						indicatorLayer.filterByActive = {areaTreeLevel: true};
					}

					dispatch(
						CommonAction.maps.addMapLayers(mapKeyToBeAdd, [indicatorLayer]),
					);
				}

				dispatch(CommonAction.maps.addMapToSet(mapKeyToBeAdd, mapSet));
				dispatch(
					CommonAction.maps.setMapMetadataModifiers(mapKeyToBeAdd, {
						areaTreeLevelKey: defaultExploreAreaTreeLevelKey,
					}),
				);

				// set map background layer
				const backgroundLayer =
					Select.unhab.layers.getDefaultBackgroundLayerByAppMode(state);
				dispatch(
					CommonAction.maps.setMapBackgroundLayer(
						mapKeyToBeAdd,
						backgroundLayer,
					),
				);

				mapSetMapCount = mapSetMapCount + 1;
			}
		}
	};
}

function updateMapView(mapKey, place) {
	return dispatch => {
		if (place?.pantherMapView) {
			dispatch(
				CommonAction.maps.updateMapAndSetView(mapKey, place?.pantherMapView),
			);
		}
	};
}

function fitMapsToSelectedFeatures() {
	return (dispatch, getState) => {
		const state = getState();
		const firstSelectionKey = Select.selections.getByKey(
			state,
			benchmarkArea2SelectionKey,
		)?.data?.featureKeysFilter?.keys?.[0];

		const secondSelectionKey = Select.selections.getByKey(
			state,
			benchmarkArea1SelectionKey,
		)?.data?.featureKeysFilter?.keys?.[0];

		dispatch(
			Action.unhab.admStructure.fitActiveMapSetToAdmKey(firstSelectionKey, 0),
		);
		dispatch(
			Action.unhab.admStructure.fitActiveMapSetToAdmKey(secondSelectionKey, 0),
		);
	};
}

function setActivePeriod(period) {
	return (dispatch, getState) => {
		const activePeriod = Select.periods.getActive(getState());
		dispatch(
			CommonAction.periods.add([
				{
					...activePeriod,
					data: {
						...activePeriod.data,
						period,
						previousPeriod: activePeriod.data.period,
					},
				},
			]),
		);
		dispatch(Action.unhab.layers.updateLayersWithActivePeriod(period));
	};
}

function setActiveFirstIndicatorLayer(mapKey) {
	return (dispatch, getState) => {
		const updatedState = getState();

		const indicatorLayersByProvidersFilteredByActiveTags =
			Select.unhab.getIndicatorLayersByProvidersFilteredByActiveTags(
				updatedState,
			);
		if (indicatorLayersByProvidersFilteredByActiveTags?.length > 0) {
			const firstDatasetIndicator =
				indicatorLayersByProvidersFilteredByActiveTags?.[0].data.datasets?.[0]
					?.data?.attributes?.[0];
			const newLayer = Select.unhab.layers.getIndicatorLayerByLayerKey(
				firstDatasetIndicator.data.configuration.columnName,
				mapKey,
			);

			const mapLayers =
				Select.maps.getLayersStateByMapKey(updatedState, mapKey) || [];

			const layerAlreadyAdded = mapLayers?.find(
				layer => layer.key === newLayer.key,
			);

			// add first possible indicator layer
			// be sure that indicator layer is not already added
			if (!layerAlreadyAdded) {
				dispatch(CommonAction.maps.addMapLayerToIndex(mapKey, newLayer, 0));
			}
		}
	};
}

// Set app defaults according to active params like datasets or areas
function setAppDefaultsByActiveParams() {
	return (dispatch, getState) => {
		const state = getState();
		const activeComparisonType =
			Select.unhab.benchmarkComparisonTypes.getActiveKey(state);
		if (activeComparisonType === areaComparisonType) {
			dispatch(
				Action.unhab.benchmark.handleComparisonTypeChange(areaComparisonType),
			);
		} else {
			dispatch(
				Action.unhab.benchmark.handleComparisonTypeChange(
					datasetsComparisonType,
				),
			);
		}
	};
}

// Add or remove map layers according to active tags/filter
function clearMapLayers(forceRemoveIndicatorsLayers) {
	return (dispatch, getState) => {
		// get layertree layers after update tags

		const updatedState = getState();

		const mapLayersToRemove = Select.unhab.getMapLayersToRemove(
			updatedState,
			forceRemoveIndicatorsLayers,
		);
		// if active map layer missing in layertree, remove it from map
		mapLayersToRemove?.forEach(map => {
			const layers = Select.unhab.layers.getMapLayersStateEnhanced(
				updatedState,
				map.key,
			);

			if (layers?.length) {
				map?.layers?.forEach(layer => {
					//if layer is dataset layer, remove it from map
					const datasetLayers = Select.unhab.layers.getDatasetLayers(
						updatedState,
						false,
					);

					if (
						datasetLayers.some(
							datasetLayer => datasetLayer.key === layer.key,
						) &&
						!layer.key.includes('-citycluster')
					) {
						dispatch(CommonAction.maps.removeMapLayer(map.key, layer.key));
					} else if (
						layer.key === admLayerKey ||
						layer.key.includes('-citycluster')
					) {
						// Do nothing with administrative layer or citycluster vector layer
						if (!forceRemoveIndicatorsLayers) {
							dispatch(setActiveFirstIndicatorLayer(map.key));
						}
						return;
					} else {
						dispatch(CommonAction.maps.removeMapLayer(map.key, layer.key));
						if (
							!forceRemoveIndicatorsLayers &&
							layer?.options?.attributes?.relative
						) {
							dispatch(setActiveFirstIndicatorLayer(map.key));
						}
					}
				});
			}
		});
	};
}

function handleViewChange(newViewKey) {
	return (dispatch, getState) => {
		const previousViewKey = Select.views.getActiveKey(getState());
		if (previousViewKey === benchmarkViewKey && newViewKey === reportViewKey) {
			dispatch(Action.views.setActiveKey(newViewKey));
			dispatch(Action.unhab.layers.setAllMapsBackgroundLayerByMode());
		} else {
			dispatch(Action.unhab.applyAndSetActive(newViewKey, Action)).then(() => {
				dispatch(setupView(newViewKey));
				dispatch(Action.unhab.layers.setAllMapsBackgroundLayerByMode());
			});
		}
	};
}

function setupView(viewKey) {
	return dispatch => {
		if (viewKey === defaultViewKey) {
			// TODO hotfix for strange selection mixing
			dispatch(
				CommonAction.selections.clearFeatureKeysFilter(
					benchmarkArea1SelectionKey,
				),
			);
			dispatch(
				CommonAction.selections.clearFeatureKeysFilter(
					benchmarkArea2SelectionKey,
				),
			);
			dispatch(CommonAction.selections.add([auSelection]));
			dispatch(CommonAction.selections.setActiveKey(auSelectionKey));

			// set mode
			// reset filter
			dispatch(
				Action.tags.setActiveKeys([
					tagBaseKey,
					defaultCoverageTagKey,
					defaultThemeTagKey,
					defaultSdgTagKey,
				]),
			);

			// reset benchmarkType
			dispatch(Action.unhab.benchmarkComparisonTypes.setActiveKey(null));

			// reset dataset
			// reset datasetSet
			dispatch(Action.unhab.datasetSets.setActive(null));

			// reset period
			dispatch(setActivePeriod(defaultPeriod));
		} else if (viewKey === benchmarkViewKey || viewKey === reportViewKey) {
			// TODO hotfix for strange selection mixing
			dispatch(CommonAction.selections.clearFeatureKeysFilter(auSelectionKey));
			dispatch(CommonAction.selections.setActiveKey(null));
			dispatch(
				CommonAction.selections.add([
					benchmarkArea1Selection,
					benchmarkArea2Selection,
				]),
			);

			// set mode
			// reset filter
			dispatch(
				Action.tags.setActiveKeys([
					tagBaseKey,
					defaultCoverageTagKey,
					defaultThemeTagKey,
					defaultSdgTagKey,
				]),
			);
			// reset benchmarkType
			dispatch(
				Action.unhab.benchmarkComparisonTypes.setActiveKey(
					defaultBenchmarkComparisonType,
				),
			);

			// reset dataset
			// reset datasetSet
			dispatch(Action.unhab.datasetSets.setActive(null));

			// reset period
			dispatch(setActivePeriod(defaultPeriod));
		} else if (viewKey === xCubeViewKey) {
			dispatch(CommonAction.selections.setActiveKey(limassolAreasSelectionKey));
		}
	};
}

export default {
	applyAndSetActive,
	configuration,
	cityClusterFeatures,
	cityClusterMethods,
	cityClusterMethodParameters,
	datasets,
	datasetSets,
	indicatorsForList,
	providers,
	layers,
	features,
	featuresForScatterPlot,
	admStructure,
	areaTreeTypes,
	benchmark,
	benchmarkComparisonTypes,
	tags,
	udtStructure,
	parameterValues,

	setMapsCount,
	setMapSetSync,
	setMapSetSyncJustScale,
	updateMapView,
	clearMapLayers,
	setActivePeriod,
	setActiveFirstIndicatorLayer,
	fitMapsToSelectedFeatures,

	handleViewChange,
	setAppDefaultsByActiveParams,
};
